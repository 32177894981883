import { createEnquete } from "@bleu/core";
import { Editable } from "@bleu/nbsr-editor";

import { Template } from "../types";

export const createTemplate = (): Editable<Template> =>
	({
		enquete: createEnquete("ご回答ありがとうございます。"),
		enqueteTitle: "",
		status: "debug",
		surveyTitle: "テスト",
	} as Editable<Template>);
