import { classBuilder } from "@lu/bem-class-builder";
import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const Attention: FunctionComponent = () => {
	const [t] = useTranslation();

	const builder = useMemo(() => classBuilder("attention"), []);

	return (
		<div className={builder(0).toString()}>
			<div className={builder(0).append("title").toString()}>{t("attention.title")}</div>
			<div className={builder(0).append("annotation").toString()}>{t("attention.annotation")}</div>
			<ol className={builder(0).append("items").toString()}>
				{[...Array(2)].map((_, i) => (
					<li key={i}>{t(`attention.item.${i}`)}</li>
				))}
			</ol>
			<div className={builder(0).append("footer").toString()}>
				<span>{t("attention.footer.prefix")}</span>
				<a rel="noopener noreferrer" target="_blank">
					{t("attention.footer.anchor")}
				</a>
				<span>{t("attention.footer.suffix")}</span>
			</div>
		</div>
	);
};
