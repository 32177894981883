import { ErrorViewer } from "@bleu/utility-components";
import { Error } from "@lu/request";
import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";

type Props = {
	error?: Error;
	text: string;
};

export const ErrorPage: FunctionComponent<Props> = React.memo(({ error, text }) => {
	return (
		<Container className="error-page">
			<h1>{text}</h1>
			{error != null && <ErrorViewer error={error} />}
		</Container>
	);
});
