import { Editable } from "@bleu/nbsr-editor";
import { Error } from "@lu/request";
import { useQuery } from "@tanstack/react-query";
import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { ErrorPage } from "../../../components/pages/Error";
import { LoadingPage } from "../../../components/pages/Loading";
import { TemplateEditorPage } from "../../../components/pages/Template";
import { fetchTemplate } from "../../../lib/queries/template";
import { Template } from "../../../types";
import { createTemplate } from "../../../utils/template";

type Props = {
	type: "create" | "edit";
};

export const TemplateEditorContainer: FunctionComponent<Props> = React.memo(({ type }) => {
	const { t } = useTranslation();

	const { templateId } = useParams<"templateId">();

	// ページタイトル（ブラウザのタブ）
	useEffect(() => {
		document.title = `${t(`pageTitle.${type}Template`)} - ${t("pageTitle.siteName")}`;
	}, [type]);

	const { data, error, isError, isLoading, isSuccess } = useQuery<Editable<Template>, Error>({
		queryKey: ["template", templateId],
		queryFn: () => {
			if (templateId == null) return createTemplate();

			return fetchTemplate(templateId);
		},
		cacheTime: Infinity,
		staleTime: Infinity,
	});

	return (
		<>
			{isError && <ErrorPage error={error} text={t("errorMessage.invalid")} />}
			{isLoading && <LoadingPage />}
			{isSuccess && <TemplateEditorPage template={data} />}
		</>
	);
});
