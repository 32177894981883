import { jobDecoder as jobDecoderBase } from "@bleu/nbsr-editor";
import { boolean, constant, Decoder, field, int, object, optional, string, union } from "@lu/typed-json";

import { JobStatus } from "../../../../server/repositories/job/model";
import { Job } from "../../types";

import { statusDecoder } from "./enqueteStatus";

const dateDecoder: Decoder<Date> = string().andMap((value) => new Date(value));

const jobStatusDecoder: Decoder<JobStatus> = union(constant("active"), constant("inactive"));

const jobDecoder: Decoder<Job> = jobDecoderBase(
	object({
		finDatetime: optional(dateDecoder),
		groupId: int(),
		isEditorSaved: optional(boolean()),
		startDatetime: optional(dateDecoder),
		status: statusDecoder,
		surveyTitle: string(),
		jobStatus: jobStatusDecoder,
	})
);

export const jobResponseDecoder: Decoder<Job> = field("job", jobDecoder);
