import { ContentsState, request } from "@lu/request";
import { VideoUploadResult } from "@lu/video-library";

import { ServerEndpoint } from "../../../../server/router/http/endpoint";
import { uploadImageResponseDecoder, UploadVideoResponseDecoder } from "../decoders";

export const uploadImage = (url: string, file: File): Promise<ContentsState<string>> => {
	const data = new FormData();
	data.append("file", file);

	return request
		.postFormData(url, data, uploadImageResponseDecoder)
		.then(({ payload }) => {
			if (payload.state === "failed") console.error(payload.error);
			return payload;
		})
		.catch((error) => {
			console.error(error);

			throw error;
		});
};

// use videog
export const uploadVideoByVideog = (file: File): Promise<ContentsState<VideoUploadResult>> => {
	const data = new FormData();
	data.append("file", file);
	return request
		.postFormData(ServerEndpoint.internal.video.videog, data, UploadVideoResponseDecoder)
		.then(({ payload }) => {
			if (payload.state === "failed") console.error(payload.error);
			return payload;
		})
		.catch((error) => {
			console.error(error);

			throw error;
		});
};

/**
 * use material api
 */
export const uploadVideoByMaterialApi = (enqueteId: string, file: File): Promise<ContentsState<VideoUploadResult>> => {
	const data = new FormData();
	data.append("file", file);
	return request
		.postFormData(`${ServerEndpoint.internal.video.materialApi}/${enqueteId}`, data, UploadVideoResponseDecoder)
		.then(({ payload }) => {
			if (payload.state === "failed") {
				console.error("[p]", payload.error);
				throw payload.error;
			}
			return payload;
		})
		.catch((error) => {
			console.error("[q]", error);

			throw error;
		});
};
