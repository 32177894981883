import React from "react";
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
	isError?: boolean;
	message?: string;
	onHide?: () => void;
	show?: boolean;
};

/**
 * - 動画アップロード中の待機用モーダル
 * - TODO: 本来はeditorのupload側で対応すべき
 */
export const UploadingModal: React.FunctionComponent<Props> = React.memo(({ isError, message, onHide, show }) => {
	const [t] = useTranslation();

	const handleClickHide = React.useCallback(() => {
		onHide();
	}, [isError, onHide]);

	return (
		<Modal
			backdrop={isError ? true : "static"}
			// keyboard={isError}
			show={show}
			onHide={handleClickHide}
		>
			<ModalBody>
				<div>{message ?? t(`uploadingModal.message.${isError ? "uploadError" : "uploading"}`)}</div>
				{isError ? <ProgressBar now={100} striped variant="secondary" /> : <ProgressBar animated now={100} />}
			</ModalBody>
			{isError && (
				<ModalFooter>
					<ButtonGroup>
						<Button onClick={handleClickHide}>{t("uploadingModal.button.close")}</Button>
					</ButtonGroup>
				</ModalFooter>
			)}
		</Modal>
	);
});
