import { convertToEditableJob, convertToMuscatJob, Editable } from "@bleu/nbsr-editor";
import { mapDecodeError, request, Result } from "@lu/request";

import { ServerEndpoint } from "../../../../server/router/http/endpoint";
import { Template } from "../../types";
import { templateResponseDecoder } from "../decoders";

export const fetchTemplate = (templateId: string): Promise<Editable<Template>> =>
	request
		.get(`${ServerEndpoint.internal.template.base}/${templateId}`, templateResponseDecoder)
		.then(({ payload }) => {
			const { error, state, value } = payload;
			if (state === "loaded") return convertToEditableJob(value);

			console.error(error);
			throw error;
		})
		.catch((error) => {
			console.error(error);

			throw error;
		});

export const upsertTemplate = async (template: Editable<Template>): Promise<Result<Editable<Template>>> => {
	const { error, ok, value } = convertToMuscatJob(template);

	if (!ok) return { payload: { error: mapDecodeError(error), state: "failed" } };

	return await (template._id == null
		? request.post(ServerEndpoint.internal.template.base, { job: value }, templateResponseDecoder)
		: request.put(
				`${ServerEndpoint.internal.template.base}/${template._id}`,
				{ template: value },
				templateResponseDecoder
		  )
	).then((res) => {
		const { state, value } = res.payload;

		if (state === "loaded") {
			const template = convertToEditableJob(value);

			return { ...res, payload: { state, value: template } };
		}

		return res as unknown as Result<Editable<Template>>;
	});
};
