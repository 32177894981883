import { classBuilder } from "@lu/bem-class-builder";
import React, { FunctionComponent, useMemo } from "react";
import { Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export type Props = {
	bg?: string;
};

export const AppBar: FunctionComponent<Props> = React.memo(({ bg = "secondary" }) => {
	const [t] = useTranslation();

	const builder = useMemo(() => classBuilder("app-bar", "brand"), []);

	return (
		<Navbar bg={bg} className={builder(0).toString()} variant="light">
			<Navbar.Brand className={builder(1).toString()}>
				<img alt={t("pageTitle.siteName")} src="/icons/logo.png" />
			</Navbar.Brand>
		</Navbar>
	);
});
