import path from "path";

import { makeEndpoint } from "../endpoint";

import { endpoint as aggregateEndpoint } from "./aggregate/endpoint";
import { endpoint as downloadEndpoint } from "./download/endpoint";
import { endpoint as enqueteEndpoint } from "./enquete/endpoint";
import { endpoint as enqueteParameterEndpoint } from "./enquete-parameter/endpoint";
import { endpoint as enqueteQuestionsEndpoint } from "./enquete-questions/endpoint";

export const externalBaseURL = "/api/e";
export const externalEndpoint = {
	aggregate: "/aggregate",
	enquete: "/enquete",
	enquetes: "/enquetes",
	editorStart: "/editor-start",
	templateEditorStart: "/template-editor-start",
	template: "/template",
	enqueteEditor: "/enquete-editor",
	enqueteParameter: "/enquete-parameter",
	enqueteQuestions: "/enquete-questions",
	filterCondition: "/filter-condition",
	filterConditionQuetion: "/filter-condition-questions",
	filterConditionCount: "/filter-condition-count",
	download: "/download",
	redirect: "/r",
} as const;

export class ExternalEndpoint {
	protected static getEndpoint(endpoint: keyof typeof externalEndpoint) {
		return path.join(externalBaseURL, externalEndpoint[endpoint]);
	}
	public static get aggregate() {
		return makeEndpoint(ExternalEndpoint.getEndpoint("aggregate"), aggregateEndpoint);
	}
	public static get enquete() {
		return makeEndpoint(ExternalEndpoint.getEndpoint("enquete"), enqueteEndpoint);
	}
	public static get enqueteParameter() {
		return makeEndpoint(ExternalEndpoint.getEndpoint("enqueteParameter"), enqueteParameterEndpoint);
	}
	public static get enqueteQuestions() {
		return makeEndpoint(ExternalEndpoint.getEndpoint("enqueteQuestions"), enqueteQuestionsEndpoint);
	}
	public static get download() {
		return makeEndpoint(ExternalEndpoint.getEndpoint("download"), downloadEndpoint);
	}
}
