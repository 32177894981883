import { jobDecoder as jobDecoderBase } from "@bleu/nbsr-editor";
import { Decoder, field, object, string } from "@lu/typed-json";

import { Template } from "../../types";

import { statusDecoder } from "./enqueteStatus";

const templateDecoder: Decoder<Template> = jobDecoderBase(
	object({
		status: statusDecoder,
		surveyTitle: string(),
	})
);

export const templateResponseDecoder: Decoder<Template> = field("template", templateDecoder);
