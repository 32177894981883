import React, { useContext } from "react";
import { createContext, FunctionComponent, PropsWithChildren } from "react";

type Props = PropsWithChildren<{ groupId: number }>;

const GroupIdContext = createContext<number>(null);

export const GroupIdProvider: FunctionComponent<Props> = React.memo(({ children, groupId }) => (
	<GroupIdContext.Provider value={groupId}>{children}</GroupIdContext.Provider>
));

export const useGroupId = (): number => useContext(GroupIdContext);
