import { Editable } from "@bleu/nbsr-editor";
import { Error } from "@lu/request";
import { useQuery } from "@tanstack/react-query";
import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import { EnqueteEditorPage } from "../../../components/pages/Enquete/Editor";
import { ErrorPage } from "../../../components/pages/Error";
import { LoadingPage } from "../../../components/pages/Loading";
import { useGroupId } from "../../../lib/customHooks";
import { fetchJob } from "../../../lib/queries/job";
import { Job } from "../../../types";
import { createJob } from "../../../utils/job";

type Props = {
	type: "create" | "edit";
};

export const EnqueteEditorContainer: FunctionComponent<Props> = React.memo(({ type }) => {
	const { t } = useTranslation();

	const { jobId } = useParams<"jobId">();

	// const queryClient = useQueryClient();

	const groupId = useGroupId();

	// ページタイトル（ブラウザのタブ）
	useEffect(() => {
		document.title = `${t(`pageTitle.${type}Enquete`)} - ${t("pageTitle.siteName")}`;
	}, [type]);

	const { data, error, isError, isLoading, isSuccess } = useQuery<Editable<Job>, Error>({
		queryKey: ["job", jobId],
		queryFn: () => {
			if (jobId == null) return createJob(groupId);

			return fetchJob(jobId);
		},
		cacheTime: Infinity,
		staleTime: Infinity,
	});

	return (
		<>
			{isError && <ErrorPage error={error} text={t("errorMessage.invalid")} />}
			{isLoading && <LoadingPage />}
			{isSuccess && <EnqueteEditorPage job={data} />}
		</>
	);
});
