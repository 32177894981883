import React from "react";
import { createRoot } from "react-dom/client";

import { App } from "./routes";

import "./lib/i18n";

const root = createRoot(document.getElementById("myApp"));

root.render(<App />);
