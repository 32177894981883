import path from "path";

import { ExternalEndpoint } from "./external/endpoint";
import { InternalEndpoint } from "./internal/endpoint";

/**
 * endpointを生成する。
 * @template K
 * @param {string} base
 * @param {{ [key in K]: string }} endpoint
 * @returns
 */
export const makeEndpoint = <K extends string>(
	base: string,
	endpoint: { [key in K]: string }
): { [key in K]: string } => {
	return (Object.entries(endpoint) as [K, string][]).reduce((a, [key, value]) => {
		return { ...a, [key]: path.join(base, value) };
	}, {}) as { [key in K]: string };
};

/**
 * URLから「:parameter-name」となっているものをreplaceする
 * @param {string} url
 * @param {{ [key in string]: string | number }} values
 * @returns {string}
 */
export const replaceParams = (url: string, values: { [key in string]: string | number }): string => {
	return Object.entries(values).reduce((a, [k, v]) => {
		return a.replaceAll(`:${k}`, v.toString());
	}, url);
};

export class ServerEndpoint {
	public static internal = InternalEndpoint;
	public static external = ExternalEndpoint;
}
