import { convertToEditableJob, convertToMuscatJob, Editable } from "@bleu/nbsr-editor";
import { mapDecodeError, request, Result } from "@lu/request";

import { ServerEndpoint } from "../../../../server/router/http/endpoint";
import { Job } from "../../types";
import { jobResponseDecoder } from "../decoders";

export const fetchJob = (jobId: string): Promise<Editable<Job>> =>
	request
		.get(`${ServerEndpoint.internal.job.base}/${jobId}`, jobResponseDecoder)
		.then(({ payload }) => {
			const { error, state, value } = payload;
			if (state === "loaded") return convertToEditableJob(value);

			console.error(error);
			throw error;
		})
		.catch((error) => {
			console.error(error);

			throw error;
		});

export const upsertJob = async (job: Editable<Job>): Promise<Result<Editable<Job>>> => {
	const { error, ok, value } = convertToMuscatJob(job);

	if (!ok) return { payload: { error: mapDecodeError(error), state: "failed" } };

	// 保存フラグを上書き
	value.isEditorSaved = true;

	return (
		job._id == null
			? request.post(ServerEndpoint.internal.job.base, { job: value }, jobResponseDecoder)
			: request.put(`${ServerEndpoint.internal.job.base}/save/${job._id}`, { job: value }, jobResponseDecoder)
	).then((res) => {
		const { state, value } = res.payload;

		if (state === "loaded") {
			const job = convertToEditableJob(value);

			return { ...res, payload: { state, value: job } };
		}

		return res as unknown as Result<Editable<Job>>;
	});
};
