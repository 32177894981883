import { LoadingSpinner } from "@bleu/utility-components";
import { Error, request } from "@lu/request";
import { useQuery } from "@tanstack/react-query";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

import { ServerEndpoint } from "../../../../server/router/http/endpoint";
import { AuthenticationResponse } from "../../../../server/types/request/http/internal/auth";
import { ErrorPage } from "../../components/pages/Error";
import { GroupIdProvider } from "../../lib/customHooks";
import { authResponseDecoder } from "../../lib/decoders";

export const WithAuth: FunctionComponent<PropsWithChildren<{}>> = React.memo(({ children }) => {
	const { t } = useTranslation();

	const { data, isError, isLoading, isSuccess } = useQuery<AuthenticationResponse, Error>({
		queryKey: ["groupId"],
		queryFn: async () => {
			return request
				.post(ServerEndpoint.internal.auth.base, {}, authResponseDecoder)
				.then(({ payload: { error, state, value } }) => {
					if (state === "loaded") return value;

					console.error(error);
					throw error;
				})
				.catch((error) => {
					console.error(error);
					throw error;
				});
		},
		refetchOnWindowFocus: true,
	});

	return (
		<>
			{isError && <ErrorPage text={t("notFound.text")} />}
			{isLoading && <LoadingSpinner />}
			{isSuccess && <GroupIdProvider {...data}>{children}</GroupIdProvider>}
		</>
	);
});
