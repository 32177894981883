import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { FunctionComponent } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AppBar } from "../components/parts/AppBar";
import { WithAuth } from "../containers/Auth";
import { EnqueteEditorContainer } from "../containers/Enquete";
import { NotFoundContainer } from "../containers/Errors";
import { TemplateEditorContainer } from "../containers/Template";

import { clientEndpoint, editorEndpoint } from "./endpoints";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

export const App: FunctionComponent = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<AppBar />
				<div className="main-container">
					<main>
						<Routes>
							{process.env.ENV === "development" && (
								<Route
									element={
										<WithAuth>
											<EnqueteEditorContainer type="create" />
										</WithAuth>
									}
									path={`${clientEndpoint.enquete}/${editorEndpoint.create}`}
								/>
							)}
							<Route
								element={
									<WithAuth>
										<EnqueteEditorContainer type="edit" />
									</WithAuth>
								}
								path={`${clientEndpoint.enquete}/:jobId/${editorEndpoint.edit}`}
							/>
							<Route
								element={
									<WithAuth>
										<TemplateEditorContainer type="edit" />
									</WithAuth>
								}
								path={`${clientEndpoint.template}/:templateId/${editorEndpoint.edit}`}
							/>
							<Route element={<NotFoundContainer />} path="*" />
						</Routes>
					</main>
				</div>
			</BrowserRouter>
		</QueryClientProvider>
	);
};
