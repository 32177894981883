import { LoadingSpinner } from "@bleu/utility-components";
import React, { FunctionComponent } from "react";
import { Container } from "react-bootstrap";

export const LoadingPage: FunctionComponent = () => {
	return (
		<Container className="loading-page">
			<LoadingSpinner />
		</Container>
	);
};
