import { Editable, EnqueteEditor } from "@bleu/nbsr-editor";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FunctionComponent, useCallback, useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";

import { ServerEndpoint } from "../../../../../../server/router/http/endpoint";
import { VideoApiType } from "../../../../../../server/types/request/http/internal/video";
import i18n from "../../../../lib/i18n";
import { uploadImage, uploadVideoByMaterialApi, uploadVideoByVideog } from "../../../../lib/queries";
import { upsertTemplate } from "../../../../lib/queries/template";
import { Template } from "../../../../types";
import { Attention } from "../../../parts/Attention";
import { UploadingModal } from "../../../parts/UploadingModal";

type Props = {
	template: Editable<Template>;
};

export const TemplateEditorPage: FunctionComponent<Props> = React.memo(({ template, ...props }) => {
	const [t] = useTranslation();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [type, _setType] = useState<VideoApiType>("materialApi");
	const [tmplt, setTemplate] = useState(template);

	const queryClient = useQueryClient();

	const [uploadVideoIsLoading, setUploadVideoIsLoading] = useState(false);
	const [uploadVideoIsError, setUploadVideoIsError] = useState(false);

	const { mutateAsync: mutateTemplate } = useMutation({
		mutationFn: async (template: Editable<Template>) =>
			upsertTemplate(template).then((res) => {
				const { state, value } = res.payload;
				if (state === "loaded") {
					queryClient.setQueryData(["template", template._id], value);
					setTemplate(value);
				}
				return res;
			}),
	});

	const handleSave = useCallback(
		async (template: Editable<Template>) => {
			return await mutateTemplate(template);
		},
		[mutateTemplate]
	);

	const handleUploadImage = useCallback(
		async (file: File) => {
			if (template._id == null)
				return { error: { message: t("temnplateEditorPage.templateIdIsUndefined") }, state: "failed" as const };

			return await uploadImage(`${ServerEndpoint.internal.image.base}/template/${template._id}`, file);
		},
		[template._id]
	);

	const uploadVideo = useCallback(
		(file: File) => {
			if (type === "videog") return uploadVideoByVideog(file);
			return uploadVideoByMaterialApi(tmplt.enqueteId, file);
		},
		[type, tmplt.enqueteId]
	);

	const { mutateAsync: handleUploadVideo } = useMutation({
		mutationFn: async (file: File) => {
			setUploadVideoIsLoading(true);
			return await uploadVideo(file);
		},
		onError: () => {
			setUploadVideoIsLoading(true);
			setUploadVideoIsError(true);
		},
		onSuccess: () => {
			setUploadVideoIsLoading(false);
		},
	});

	const handleHide = useCallback(() => {
		setUploadVideoIsLoading(false);
		setUploadVideoIsError(false);
	}, [setUploadVideoIsLoading, setUploadVideoIsError]);

	return (
		<div className="template-editor-page">
			{(uploadVideoIsLoading || uploadVideoIsError) && (
				<UploadingModal
					isError={uploadVideoIsError}
					show={uploadVideoIsLoading || uploadVideoIsError}
					onHide={handleHide}
				></UploadingModal>
			)}
			<EnqueteEditor
				job={tmplt}
				variants={{
					controlSecondary: "outline-primary",
				}}
				webEndpoints={{
					debug: ServerEndpoint.internal.redirect.templateDebugBase,
					enquete: ServerEndpoint.internal.redirect.templateDebugBase,
					preview: ServerEndpoint.internal.redirect.templatePreviewBase,
				}}
				onSave={handleSave}
				onUpdate={setTemplate}
				onUploadImage={handleUploadImage}
				onUploadVideo={handleUploadVideo}
				{...props}
			>
				<I18nextProvider i18n={i18n}>
					<Attention />
				</I18nextProvider>
			</EnqueteEditor>
		</div>
	);
});
