const previewBase = "/preview";
const debugBase = "/debug";
const enquetePreviewBase = `${previewBase}/enquete`;
const enqueteDebugBase = `${debugBase}/enquete`;
const templatePreviewBase = `${previewBase}/template`;
const templateDebugBase = `${debugBase}/template`;

export const endpoint = {
	base: "",
	preview: previewBase,
	debug: debugBase,

	enquetePreviewBase,
	enqueteDebugBase,
	enquetePreview: `${enquetePreviewBase}/:enqueteId`,
	enqueteDebug: `${enqueteDebugBase}/:enqueteId`,

	templatePreviewBase,
	templateDebugBase,
	templatePreview: `${templatePreviewBase}/:enqueteId`,
	templateDebug: `${templateDebugBase}/:enqueteId`,
} as const;
