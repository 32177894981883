import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ErrorPage } from "../../components/pages/Error";

export const NotFoundContainer: FunctionComponent = React.memo(() => {
	const [t] = useTranslation();

	useEffect(() => {
		document.title = `${t("pageTitle.notFound")} - ${t("pageTitle.siteName")}`;
	}, []);

	return <ErrorPage text={t("errorMessage.notFound")} />;
});
