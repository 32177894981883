import { Editable, EnqueteEditor } from "@bleu/nbsr-editor";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FunctionComponent, useCallback, useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";

import { ServerEndpoint } from "../../../../../../server/router/http/endpoint";
import { VideoApiType } from "../../../../../../server/types/request/http/internal/video";
import i18n from "../../../../lib/i18n";
import { uploadImage, uploadVideoByMaterialApi, uploadVideoByVideog } from "../../../../lib/queries";
import { upsertJob } from "../../../../lib/queries/job";
import { Job } from "../../../../types";
import { Attention } from "../../../parts/Attention";
import { UploadingModal } from "../../../parts/UploadingModal";

type Props = {
	job: Editable<Job>;
};

export const EnqueteEditorPage: FunctionComponent<Props> = React.memo(({ job, ...props }) => {
	const [t] = useTranslation();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [type, _setType] = useState<VideoApiType>("materialApi");
	const queryClient = useQueryClient();

	const [_job, setJob] = useState(job);

	const [uploadVideoIsLoading, setUploadVideoIsLoading] = useState(false);
	const [uploadVideoIsError, setUploadVideoIsError] = useState(false);

	const { mutateAsync: mutateJob } = useMutation({
		mutationFn: async (job: Editable<Job>) =>
			upsertJob(job).then((res) => {
				const { state, value } = res.payload;
				if (state === "loaded") {
					queryClient.setQueryData(["job", job._id], value);
					setJob(value);
				}
				return res;
			}),
	});

	const handleSave = useCallback(
		async (job: Editable<Job>) => {
			return await mutateJob(job);
		},
		[mutateJob]
	);

	const { mutateAsync: handleUploadImage } = useMutation({
		mutationFn: async (file: File) => {
			if (job._id == null)
				return { error: { message: t("enqueteEditorPage.jobIdIsUndefined") }, state: "failed" as const };

			return await uploadImage(`${ServerEndpoint.internal.image.base}/enquete/${job._id}`, file);
		},
	});

	const uploadVideo = useCallback(
		async (file: File) => {
			if (type === "videog") return uploadVideoByVideog(file);
			return await uploadVideoByMaterialApi(job.enqueteId, file);
		},
		[type, job.enqueteId]
	);

	const { mutateAsync: handleUploadVideo } = useMutation({
		mutationFn: async (file: File) => {
			setUploadVideoIsLoading(true);
			return await uploadVideo(file);
		},
		onError: () => {
			setUploadVideoIsLoading(true);
			setUploadVideoIsError(true);
		},
		onSuccess: () => {
			setUploadVideoIsLoading(false);
		},
	});

	const handleHide = useCallback(() => {
		setUploadVideoIsLoading(false);
		setUploadVideoIsError(false);
	}, [setUploadVideoIsLoading, setUploadVideoIsError]);

	return (
		<div className="enquete-editor-page">
			{(uploadVideoIsLoading || uploadVideoIsError) && (
				<UploadingModal
					isError={uploadVideoIsError}
					show={uploadVideoIsLoading || uploadVideoIsError}
					onHide={handleHide}
				></UploadingModal>
			)}
			<EnqueteEditor
				job={_job}
				variants={{
					controlSecondary: "outline-secondary",
				}}
				webEndpoints={{
					debug: ServerEndpoint.internal.redirect.enqueteDebugBase,
					enquete: ServerEndpoint.internal.redirect.enqueteDebugBase,
					preview: ServerEndpoint.internal.redirect.enquetePreviewBase,
				}}
				onSave={handleSave}
				onUpdate={setJob}
				onUploadImage={handleUploadImage}
				onUploadVideo={handleUploadVideo}
				{...props}
			>
				<I18nextProvider i18n={i18n}>
					<Attention />
				</I18nextProvider>
			</EnqueteEditor>
		</div>
	);
});
