import path from "path";

import { makeEndpoint } from "../endpoint";

import { endpoint as authEndpoint } from "./auth/endpoint";
import { endpoint as imageEndpoint } from "./image/endpoint";
import { endpoint as jobEndpoint } from "./job/endpoint";
import { endpoint as redirectEndpoint } from "./redirect/endpoint";
import { endpoint as templateEndpoint } from "./template/endpoint";
import { endpoint as videoEndpoint } from "./video/endpoint";

export const internalBaseURL = "/api/i";

export const internalEndpoint = {
	auth: "/auth",
	job: "/job",
	image: "/image",
	video: "/video",
	template: "/template",
	redirect: "/r",
} as const;

export class InternalEndpoint {
	protected static getEndpoint(endpoint: keyof typeof internalEndpoint) {
		return path.join(internalBaseURL, internalEndpoint[endpoint]);
	}
	public static get auth() {
		return makeEndpoint(InternalEndpoint.getEndpoint("auth"), authEndpoint);
	}
	public static get image() {
		return makeEndpoint(InternalEndpoint.getEndpoint("image"), imageEndpoint);
	}
	public static get job() {
		return makeEndpoint(InternalEndpoint.getEndpoint("job"), jobEndpoint);
	}
	public static get redirect() {
		return makeEndpoint(InternalEndpoint.getEndpoint("redirect"), redirectEndpoint);
	}
	public static get template() {
		return makeEndpoint(InternalEndpoint.getEndpoint("template"), templateEndpoint);
	}
	public static get video() {
		return makeEndpoint(InternalEndpoint.getEndpoint("video"), videoEndpoint);
	}
}
