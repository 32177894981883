import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import jaJson from "../../locales/ja.json";

// i18nextの初期化
i18n.use(initReactI18next).init({
	resources: {
		ja: { translation: jaJson },
	},
	lng: "ja",
	fallbackLng: "ja",
	interpolation: { escapeValue: false },
});

export default i18n;
