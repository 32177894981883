import { createEnquete } from "@bleu/core";
import { Editable } from "@bleu/nbsr-editor";

import { Job } from "../types";

export const createJob = (groupId: number): Editable<Job> =>
	({
		enquete: createEnquete("ご回答ありがとうございます。"),
		enqueteTitle: "",
		groupId,
		status: "debug",
		surveyTitle: "テスト",
	} as Editable<Job>);
