import { Decoder, field, object, string } from "@lu/typed-json";
import { VideoUploadResult } from "@lu/video-library";

export const uploadImageResponseDecoder: Decoder<string> = field("url", string());

export const UploadVideoResponseDecoder: Decoder<VideoUploadResult> = object({
	html: string(),
	id: string(),
	script: string(),
	url: string(),
});
